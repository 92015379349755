import Breadcrumb from "@/components/Common/Breadcrumb";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React from "react";
import { useLocation } from "@reach/router";
import Partners from "@/components/Home/Partners";
import Quality from "@/components/About2/Quality";
import PartnersQ from "@/components/About2/Quality/Partners";

const QualityP = () => {
  return (
    <Layout>
      <SEO title="Quality" canonical={`${useLocation().host}`} />
      <Breadcrumb
        name="Quality & Certifications"
        pathName="Quality & Certifications"
        imgUrl="/images/fotter-sub.jpeg"
      />
      <Quality />
      <PartnersQ />
      <Partners />
    </Layout>
  );
};

export default QualityP;
