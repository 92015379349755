import { useViewport } from "@/hooks/useViewport";
import React from "react";

const Quality = () => {
  const { isMobile } = useViewport();

  const certifications = [
    {
      image: "/images/quality/c-1.png",
      title: "Air Agency Certificate",
      subtitle: "Certifications",
      file: "/files/FAA Certificate 06-24-2022 MEKCO Group dba SATTO Repair Center USA.pdf",
    },
    {
      image: "/images/quality/c-2.png",
      title: "EASA Part - 145 Approval",
      subtitle: "Certifications",
      file: "/files/EASA_Certificate_2023_2025_MEKCO_Group_dba_SATTO_Repair_Center_USA.pdf",
    },
  ];

  const legalDocuments = [
    {
      image: "/images/quality/prime-file-pdf.svg",
      title: "Operations Specifications",
      subtitle: "legal documents",
      file: "/files/MEKCO Group FAA Ops Spec - Complete  Package 2022 Nov 09.pdf",
    },
    {
      image: "/images/quality/prime-file-pdf.svg",
      title: "Anti-Drug & Alcohol Misuse Prevent Program",
      subtitle: "Legal Documents",
      file: "/files/A449-Antidrug-and-Alcohol-Misuse-Program-OpSpecs-FAA.pdf",
    },
    {
      image: "/images/quality/prime-file-pdf.svg",
      title: "Term & Conditions",
      subtitle: "Legal Documents",
      file: "/files/General-Conditions-of-Sale-MEKCO-Group-2019-Jan-01.pdf",
    },
  ];

  return (
    <div className="rts-quality-area pt--0 pb--50">
      <div className="container pt--50" style={{ maxWidth: "1200px" }}>
        <h5>Certifications</h5>
        <div className="card-wrapper cert">
          {certifications.map((certification, index) => (
            <div
              className={`card ${isMobile ? "full-width-card" : ""}`}
              key={index}
            >
              <div className="card-ct">
                <img src={certification.image} alt="Certification Image" />
              </div>
              <div className="content-wp">
                <h4>{certification.title}</h4>
                <h6>{certification.subtitle}</h6>
                <a className="btn-download" download href={certification.file}>
                  Download PDF
                </a>
              </div>
            </div>
          ))}
        </div>
        <hr className="separator-ab mt--30 mb--30" />
        <h5>Legal Documents</h5>
        <div className="card-wrapper legal-doc">
          {legalDocuments.map((doc, index) => (
            <div
              className={`card ${isMobile ? "full-width-card" : ""}`}
              key={index}
            >
              <div className="card-ct bg-qa">
                <img src={doc.image} alt="Document Image" />
              </div>
              <div className="content-wp">
                <h4>{doc.title}</h4>
                <h6>{doc.subtitle}</h6>
                <a className="btn-download" download href={doc.file}>
                  Download PDF
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Quality;
