import React from "react";
import { useViewport } from "@/hooks/useViewport";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const clients = [
  {
    id: 1,
    img: "dgac.svg",
    url: "https://www.ecologie.gouv.fr/en/french-civil-aviation-authority",
  },
  {
    id: 2,
    img: "EASA trans.webp",
    url: "https://www.easa.europa.eu/",
  },
  {
    id: 3,
    img: "FAA.webp",
    url: "https://www.faa.gov/",
  },
  {
    id: 4,
    img: "anac.svg",
    url: "https://www.anac.gov.br/en/safety",
  },
  {
    id: 5,
    img: "agencia-federal-ac.svg",
    url: "https://www.gob.mx/sct",
  },
  {
    id: 6,
    img: "Autoridad_Aeronautica_Civil.svg",
    url: "https://www.aeronautica.gob.pa/",
  },
];

const PartnersQ = () => {
  const { isMobile } = useViewport();
  return (
    <div className="rts-quality-clients-area mb--60 rts-bg top-parteners mt--0">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
            <h3 className="rts-top-title mb-0">OUR TRUSTED CLIENTS</h3>
            <h2 className="rts-section-title justify-content-center mb--20">
              Partners
            </h2>
          </div>
        </div>
        {!isMobile ? (
          <div className="row justify-content-center">
            <div className="col-8 d-flex flex-wrap">
              {clients.map(({ id, img, url }) => (
                <div
                  key={id}
                  className="col-sm-6 col-md-6 col-lg-4 mb-4 d-flex justify-content-center align-items-center"
                  style={{ height: "130px", padding: "1rem" }}
                >
                  <a href={url} target="_blank" rel="noreferrer">
                    <img
                      src={`/images/client/${img}`}
                      alt="Client Logo"
                      style={{ maxHeight: "180px" }}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Swiper slidesPerView={2} spaceBetween={0} loop={true}>
            {clients.map(({ id, img, url }) => (
              <SwiperSlide
                key={`partnerq-${id}`}
                style={{ background: "transparent" }}
              >
                <a href={url} target="_blank" rel="noreferrer">
                  <img
                    src={`/images/client/${img}`}
                    alt="Client Logo"
                    style={{ maxHeight: "180px" }}
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default PartnersQ;
